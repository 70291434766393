import { AppHeader } from "./index"
import { Outlet } from "react-router-dom"
import AppSidebar from "./AppSidebar"
import { Backdrop } from "@mui/material"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { backdropShowActions } from "src/store/features/backdrop/backdropShowSlice"

const DefaultLayout = () => {
  //On récupère l'état du store pour afficher le backdrop
  const dispatch = useDispatch()
  const backdropShow = useSelector((state) => state.backdropShow.backdropShow)
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backdropFilter: "blur(2px)",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={backdropShow}
        onClick={() => dispatch(backdropShowActions.show(!backdropShow))}
      ></Backdrop>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 p-3">
          <Outlet />
        </div>
        {/* <AppFooter /> */}
      </div>
    </>
  )
}

export default DefaultLayout

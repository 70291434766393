import { createSlice } from "@reduxjs/toolkit";

const initialSidebarState = {
  sidebarShow: true,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: initialSidebarState,
  reducers: {
    show: (state, action) => {
      state.sidebarShow = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const sidebarActions = sidebarSlice.actions;

export default sidebarSlice.reducer;

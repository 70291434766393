import { createSlice } from "@reduxjs/toolkit"

const initialReloadComponentState = {
  reloadComponentToggle: false,
}

export const reloadComponentSlice = createSlice({
  name: "reloadComponent",
  initialState: initialReloadComponentState,
  reducers: {
    toggle: (state, action) => {
      state.reloadComponentToggle = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const reloadComponentActions = reloadComponentSlice.actions

export default reloadComponentSlice.reducer
